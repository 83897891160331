import {ItemBase, StoreBase} from "./store";
import { observable, computed, action, toJS, autorun } from "mobx"

const stage = "dev";
const service = "tube";
const baseUrl = `https://kpuwilg4xj.execute-api.ap-northeast-1.amazonaws.com/${stage}`;

export default class TubeStore extends StoreBase {
    Item = Tube;
    @observable types = [];
    @observable selectedCat = 'Action';
    modalStore = null;

    constructor(modalStore) {
        super();
        this.modalStore = modalStore
    }


    @computed get trees() {
        const generateNodes = (types) => types.map(e => ({
            id: e,
            hasCaret: false,
            icon: "document",
            label: e,
            isSelected: e === this.selectedCat,
            isExpanded: false,
            childNodes: null
        }));

        return generateNodes(this.types)
    }

    @action async fetchType() {
        this.startLoading();
        let [err, res] = await this.get(`${baseUrl}/category`);
        console.log('fetchType', err, res.body)

        this.types = res.body;
    }

    @action async create(data) {
        this.startLoading();
        let [err, res] = await this.post(`${baseUrl}/`, data);

        if (res.body.success) {
            this.stopLoading("Create successfully")
            this.getByCat(data.category)
            this.modalStore.close();
        } else {
            this.stopLoadingErr("Create failed")
        }
    }

    @action async update(id, data) {
        this.startLoading();
        let [err, res] = await this.put(`${baseUrl}/${id}`, data);

        if (res.body.success) {
            this.stopLoading("Update successfully");
            this.getByCat(data.category);
            this.modalStore.close();
        } else {
            this.stopLoadingErr("Update failed")
        }
    }

    @action async destroy(data, afterDestroy) {
        this.startLoading();
        let [err, res] = await this.delete(`${baseUrl}/${data.id}`);

        if (res.body.success) {
            this.getByCat(data.category);
            this.stopLoading("Delete successfully");
            this.modalStore.close();
            afterDestroy()
        } else {
            this.stopLoadingErr("Delete failed")
        }
    }

    @action async getByCat(type) {
        this.startLoading();
        this.selectedCat = type;
        let [err, res] = await this.get(`${baseUrl}/cat/${type}`);

        this.updateFromServer(res.body.data.Items)

        this.stopLoading()
    }
}

class Tube extends ItemBase {
    @observable id;
    @observable title;
    @observable cast;
    @observable category;
    @observable about;
    @observable tags = [];
    @observable thumbnail;
    @observable cover;
    @observable video;
    @observable duration;
    @observable feature;
    @observable created_at;
    @observable updated_at;

    store = null;

    constructor(store, data) {
        super(store);
        this.store = store;

        this.id = data.id;
        this.title = data.title;
        this.cast = data.cast;
        this.category = data.category;
        this.about = data.about;
        this.tags = data.tags;
        this.thumbnail = data.thumbnail;
        this.cover = data.cover;
        this.video = data.video;
        this.duration = data.duration;
        this.feature = data.feature;
        this.created_at = new Date(Number(data.created_at));
        this.updated_at = new Date(data.updated_at);
    }

    updateFromJson(data) {
        console.log(data);
        this.title = data.title;
        this.cast = data.cast;
        this.category = data.category;
        this.about = data.about;
        this.tags = data.tags;
        this.thumbnail = data.thumbnail;
        this.cover = data.cover;
        this.video = data.video;
        this.duration = data.duration;
        this.feature = data.feature;
        this.created_at = new Date(Number(data.created_at));
        this.updated_at = new Date(data.updated_at);
    }
}
