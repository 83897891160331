import React from 'react';
import {BrowserRouter as Router, Route, Switch, Link} from "react-router-dom";
import {Provider as MobXProvider} from "mobx-react";

import {
    Alignment,
    Button,
    Navbar,
} from "@blueprintjs/core";
import {NotificationContainer} from 'react-notifications';

import Home from './pages/home'
import Tube from './pages/tube'
import Record from './pages/record'
import NotFound from './pages/404'
import {Auth} from 'aws-amplify';


import RecordStore from "./stores/record";
import TubeStore from "./stores/tube";
import Modal from "./stores/modal";

const modalStore = new Modal();
const recordStore = new RecordStore(modalStore);
const tubeStore = new TubeStore(modalStore);

class App extends React.PureComponent {
    handleSignOut = () => {
        Auth.signOut()
            .then(() => this.props.onStateChange('signIn', {}))
            .catch(err => console.log(err));
    };

    render() {
        const {authState, authData} = this.props;
        console.log(authData);
        return (
            <Router>
                {authState === 'signedIn' &&
                <div className="App">
                    <Navbar>
                        <Navbar.Group align={Alignment.LEFT}>
                            <Navbar.Heading>GHS</Navbar.Heading>
                            <Navbar.Divider/>
                            {/*<Link to="/">*/}
                            {/*    <Button className="bp3-minimal" icon="home" text="Dashboard"/>*/}
                            {/*</Link>*/}
                            <Link to="/">
                                <Button className="bp3-minimal" icon="th-list" text="Tube"/>
                            </Link>
                            <Link to="/record">
                                <Button className="bp3-minimal" icon="user" text="Record"/>
                            </Link>
                        </Navbar.Group>
                        <Navbar.Group align={Alignment.RIGHT}>
                            <Link to="/profile">
                                <Button className="bp3-minimal" icon="user" text={authData.username}/>
                            </Link>
                            <Button className="bp3-minimal" icon="log-out" text="Sign Out"
                                    onClick={this.handleSignOut}/>
                        </Navbar.Group>
                    </Navbar>
                    <MobXProvider
                        recordStore={recordStore}
                        tubeStore={tubeStore}
                        modalStore={modalStore}
                    >
                        <Switch>
                            {/*<Route path="/" exact component={Home}/>*/}
                            <Route path="/" exact component={Tube}/>
                            <Route path="/record" component={Record}/>
                            <Route component={NotFound}/>
                        </Switch>
                    </MobXProvider>
                    <NotificationContainer/>
                </div>}
            </Router>
        );
    }

}

export default App;
