import React from 'react';
import {Box, Flex} from 'reflexbox'
import {Card, Elevation} from "@blueprintjs/core";
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

const data = [
    {
        name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
    },
    {
        name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    },
    {
        name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
    },
    {
        name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    },
    {
        name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
    },
    {
        name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    },
    {
        name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    },
];

export default class Admin extends React.PureComponent {
    render() {
        const demo = <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
                top: 5, right: 30, left: 20, bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
        </LineChart>

        return <div>
            <Flex align='top' justify="center" m={2}>
                <Box w={1 / 2}>
                    <Card interactive={false} elevation={Elevation.TWO} style={{width: '100%'}}>
                        <h4><a href="#">APP下載/登入人數</a></h4>
                        {demo}
                    </Card>
                </Box>
                <Box px={2}  w={1 / 2}>
                    <Card interactive={false} elevation={Elevation.TWO} style={{width: '100%'}}>
                        <h4><a href="#">成交訂單數</a></h4>
                        {demo}
                    </Card>
                </Box>
            </Flex>
            <Flex align='top' justify="center" m={2}>
                <Box w={1 / 2}>
                    <Card interactive={false} elevation={Elevation.TWO} style={{width: '100%'}}>
                        <h4><a href="#">前 20 大成交商品品項排名/月</a></h4>
                        {demo}
                    </Card>
                </Box>
                <Box px={2} w={1 / 2}>
                    <Card interactive={false} elevation={Elevation.TWO} style={{width: '100%'}}>
                        <h4><a href="#">維修預約數-店/月</a></h4>
                        {demo}
                    </Card>
                </Box>
            </Flex>
            <Flex align='top' justify="center" m={2}>
                <Box w={1 / 2}>
                    <Card interactive={false} elevation={Elevation.TWO} style={{width: '100%'}}>
                        <h4><a href="#">保養預約數-店/月</a></h4>
                        {demo}
                    </Card>
                </Box>
                <Box px={2} w={1 / 2}>
                    <Card interactive={false} elevation={Elevation.TWO} style={{width: '100%'}}>
                        <h4><a href="#">商品好評品項排名/月</a></h4>
                        {demo}
                    </Card>
                </Box>
            </Flex>
        </div>
    }
}