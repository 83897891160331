import React from 'react';
import {NonIdealState, Button} from "@blueprintjs/core";
import {Flex} from 'reflexbox'

export default class Admin extends React.PureComponent {
    render() {
        const {history} = this.props;

        return <Flex align='center' justify="center" p={2} style={{height: "90vh"}}>
            <NonIdealState
                icon={"search"}
                title="Page Not Found"
                description={[
                    "Your link didn't match any page.",
                    <br/>,
                    "Please go back to home page"
                ]}
                action={<Button icon="home" text="Back to home" onClick={() => history.push('/')}/>}
            />
        </Flex>
    }
}