import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import {Authenticator, Greetings, ForgotPassword, RequireNewPassword, SignIn, SignUp, ConfirmSignUp, VerifyContact, TOTPSetup, Loading} from "aws-amplify-react";

Amplify.configure({
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
        // REQUIRED - Amazon Cognito Region
        region: 'ap-northeast-1',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: 'ap-northeast-1',
        identityPoolId: "ap-northeast-1:daad4a9d-d61d-48d7-b8c8-95283fbd7e97",
        // OPTIONAL - Amazon Cognito User Pool ID
        // userPoolId: 'ap-northeast-1_6AS1JxKb0',
        userPoolId: 'ap-northeast-1_G7MKdB7n2',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        // userPoolWebClientId: '66hagcas14mic1vv8og266gmt7',
        userPoolWebClientId: 'ifqrpbg0sr8hlb987n5m7c6bl',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //     domain: 'localhost:3000',
        // // OPTIONAL - Cookie path
        //     path: '/',
        // // OPTIONAL - Cookie expiration in days
        //     expires: 365,
        // // OPTIONAL - Cookie secure flag
        // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //     secure: false
        // },

        // OPTIONAL - customized storage object
        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    Storage: {
        region: 'ap-northeast-1',
        bucket: 'noovo-tube',
        identityPoolId: "ap-northeast-1:daad4a9d-d61d-48d7-b8c8-95283fbd7e97",
        level: 'public'
    },
});

ReactDOM.render(<Authenticator hideDefault={true}>
    <App/>
    <SignIn/>
    <SignUp/>
    <ConfirmSignUp/>
    <VerifyContact/>
    <TOTPSetup/>
    <Loading/>
    <ForgotPassword/>
    <RequireNewPassword/>
</Authenticator>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
