import React from 'react';
import {Card, Elevation} from "@blueprintjs/core";
import {Flex} from 'reflexbox'
import {inject, observer} from "mobx-react";
import moment from 'moment';
import DataTable from "../components/DataTables";

export default @inject("recordStore")
@observer
class Order extends React.PureComponent {
    constructor(props) {
        super(props);
        this.props.recordStore.fetch('create');
    }

    render() {
        const {recordStore} = this.props;

        const columns = [{
            title: 'Event Type',
            key: 'type'
        }, {
            title: 'Title',
            key: 'title'
        }, {
            title: 'Created At',
            key: 'created_at',
            render: e => moment(e).format('YYYY-MM-DD HH:mm')
        }];

        return <Flex align='center' justify="center" m={2}>
            <Card interactive={false} elevation={Elevation.TWO} style={{width: '100%'}}>
                <DataTable dataSource={recordStore.all} columns={columns}/>
            </Card>
        </Flex>
    }
}