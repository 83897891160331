import request from 'superagent';
import co from 'await-to-js';
import {Auth} from 'aws-amplify';
const Cookies = {
    get: () => {}
};
const DEBUG = false;


const convertFieldData = (data) => {
    for (let key in data) {
        if (data.hasOwnProperty(key) && (typeof data[key] === 'undefined' || data[key] === null)) {
            data[key] = ''
        }
    }
};

export async function get(url) {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    let [err, data] = await co(request.get(url).accept('application/json').set('Authorization', token));

    return [err, data]
}

export async function post(url, data) {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    let [err, res] = await co(request.post(url).send(data).accept('application/json').set('Authorization', token));
    return [err, res]
}

export async function put(url, data, process, process_error) {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    let [err, res] = await co(request.put(url).send(data).accept('application/json').set('Authorization', token));
    return [err, res]
}

export async function del(url, data, process, process_error) {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    let [err, res] = await co(request.del(url).send(data).accept('application/json').set('Authorization', token));
    return [err, res]
}

// POST request with file
export function upload(url,data,file,process, process_error, progress) {
    if (DEBUG) {
        process(data);
        return 0
    }
    request.put(url)
        .field(data).set('Authorization',Cookies.get("Authorization"))
        .attach('file', file)
        .accept('application/json')
        .on('progress', function(e) {
            console.log('Percentage done: ', e.percent);
            progress(e.percent)
        })
        .on('error', process_error)
        .end(function(err, res) {
            try {
                if (typeof res === 'undefined') {
                    process_error("Internal Error")
                    return 1
                }
                if (res.body.result === "Succeed") {
                    process(res.body.data);
                } else if (res.body.result === 'Expired') {
                    process_error(res.body);
                } else {
                    process_error(res.body);
                }
            } catch (e) {
                process_error(e)
            }
        });
}

export function uploadField(url, method, data,filename,file,process, process_error, progress = () => {}) {
    const req = method === 'post'? request.post: request.put;

    let newData = Object.assign({}, data);

    Object.keys(newData).forEach((key) => (
        newData[key] == null || typeof newData[key] === 'undefined' || (typeof newData[key] === 'string' && newData.length === 0)) && delete newData[key]);

    req(url)
        .field(newData).set('Authorization',Cookies.get("Authorization"))
        .attach(filename, file)
        .accept('application/json')
        .on('progress', function(e) {
            console.log('Percentage done: ', e.percent);
            progress(e.percent)
        })
        .on('error', process_error)
        .end(function(err, res) {
            try {
                if (typeof res === 'undefined') {
                    process_error("Internal Error")
                    return 1
                }
                if (res.body.result === "Succeed") {
                    process(res.body.data);
                } else if (res.body.result === 'Expired') {
                    process_error(res.body);
                } else {
                    process_error(res.body);
                }
            } catch (e) {
                process_error(e)
            }
        });
}