import {ItemBase, StoreBase} from "./store";
import { observable, computed, action, toJS, autorun } from "mobx"

const stage = "dev";
const service = "tube";
const baseUrl = `https://kpuwilg4xj.execute-api.ap-northeast-1.amazonaws.com/${stage}`;

export default class RecordStore extends StoreBase {
    Item = Record;
    @observable types = [];

    modalStore = null;

    constructor(modalStore) {
        super();
        this.modalStore = modalStore
    }

    @computed get trees() {
        const generateNodes = (types) => types.map(e => ({
            id: e,
            hasCaret: false,
            icon: "document",
            label: e,
            isExpanded: false,
            childNodes: null
        }));

        return generateNodes(this.types)
    }

    @action async fetch(type) {
        this.startLoading();
        let [err, res] = await this.get(`${baseUrl}/record/${type}`);

        this.updateFromServer(res.body.data.Items)
    }
}

class Record extends ItemBase {
    @observable type;
    @observable info;
    @observable created_at;

    @computed get title() {
        return this.info.title
    }


    constructor(store, data) {
        super(store);
        this.type = data.type;
        this.created_at = new Date(Number(data.created_at));
        this.info = data.info;
    }

    updateFromJson(data) {
        console.log(data);
        this.type = data.type;
        this.created_at = new Date(Number(data.created_at));
        this.info = data.info;
    }
}
