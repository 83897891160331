import { observable, computed, action, autorun, toJS } from "mobx"
import {NotificationManager} from "react-notifications";
import {get, put, post, del, upload, uploadField, postForm, putForm, delForm} from './request';
const LIST = 'items';

export class StoreBase {
    // @override
    Item = ItemBase;

    get = get;
    put = put;
    delete = del;
    post = post;
    upload = upload;
    uploadField = uploadField;

    Message = NotificationManager;

    constructor() {
        autorun(() => console.log(this.constructor.name, this.report));
    }

    @computed get report() {
        return toJS(this[LIST])
    }

    @observable items = [];

    @observable isLoading = false;
    @observable loadingError = undefined;

    @action startLoading() {
        this.isLoading = true;
    }

    @action stopLoading(withMessage) {
        this.isLoading = false;
        if (withMessage) {
            NotificationManager.success(withMessage)
        }
    }

    @action stopLoadingErr(withError) {
        this.isLoading = false;
        if (withError) {
            NotificationManager.error(withError)
        }
    }

    @computed get all() {
        return this.items.sort((a,b) => a.id > b.id? 1: -1)
    }

    @computed get allByDate() {
        return this.items.sort((a,b) => new Date(a.date) < new Date(b.date)? 1: -1)
    }

    @computed get allByTime() {
        return this.items.sort((a,b) => new Date(a.time) < new Date(b.time)? 1: -1)
    }

    getById(id) {
        return this.items.find(e => e.id === id)
    }

    // @override
    fetch() {
    }

    // @override
    create(data) {
    }

    // @override
    destroy(item) {
    }

    // @override
    update(item, json) {
    }

    updateFromServer(data, stores) {
        this[LIST] = []
        data && data.forEach(json => {
            const item = this[LIST].find(item => item.id === json.id); // item in json
            if (item) {
                item.updateFromJson(json);
            } else {
                this.add(json, stores)
            }
        })
    }

    @action add(json, stores) {
        const item = new this.Item(this, json, stores);
        this[LIST].push(item);
        return item;
    }

    @action del(item) {
        this[LIST].splice(this[LIST].indexOf(item), 1);
        item.dispose();
    }

    @action clean(LIST = "items") {
        this[LIST] = []
    }
}

export class ItemBase {
    id = null;

    store = null;

    get = get;
    put = put;
    post = post;
    upload = upload;

    @observable isLoading = false;
    @observable loadingError = undefined;

    @action startLoading() {
        this.isLoading = true;
    }

    @action stopLoading(withMessage) {
        this.isLoading = false;
        if (withMessage) {
            NotificationManager.success(withMessage)
        }
    }

    @action stopLoadingErr(withError) {
        this.isLoading = false;
        if (withError) {
            NotificationManager.error(withError)
        }
    }

    constructor(store) {
        this.store = store;
    }

    destroy() {
        this.store.destroy(this);
    }

    update(data) {
        this.store.update(this, data);
    }

    updateFromJson(data) {

    }

    dispose() {
        // clean up the observer
    }
}