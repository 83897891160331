import { observable, computed, action, autorun, toJS } from "mobx"


export default class Modal {
	@observable type = null;
	@observable data = {};
	afterClose = () => {};

	@computed get visible() {
		return this.type;
	}

    constructor() {
        autorun(() => console.log('modalStore',this.report));
    }

	@computed get report() {
        return {
            type: this.type,
            data: toJS(this.data),
        }
    }

	@action show(type = 'All', data, afterClose = () => {}) {
		this.type = type;
		this.data = data;
		this.afterClose = afterClose;
	}

	@action.bound close() {
		this.type = null;
		this.data = {};
		this.afterClose();
		this.afterClose = () => {}
	}
}