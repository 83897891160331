import React from 'react';
import {Column, Cell, Table, TableLoadingOption} from "@blueprintjs/table";
import PropTypes from 'prop-types';

class DataTable extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {dataSource, columns, onFocusedCell, loading, perPage} = this.props;

        const renderCells = (column) => (row) => {
            return <Cell>{column.render? column.render(dataSource[row][column.key], dataSource[row]): dataSource[row][column.key]}</Cell>
        };

        return <Table numRows={dataSource.length} loadingOptions={loading? [TableLoadingOption.CELLS]: []} enableFocusedCell onFocusedCell={cell => onFocusedCell(cell, dataSource[cell.row])}>
            {columns.map(column =>
                <Column name={column.title} key={column.key} cellRenderer={renderCells(column)}/>
            )}
        </Table>
    }
}

DataTable.propTypes = {
    columns: PropTypes.array.isRequired,
    dataSource: PropTypes.array.isRequired,
    perPage: PropTypes.number,
    wrapperClass: PropTypes.string,
    loading: PropTypes.bool,
    selectable: PropTypes.bool,
    onFocusedCell: PropTypes.func,
};

DataTable.defaultProps = {
    perPage: 10,
    columns: [],
    dataSource: [],
    loading: false,
    onFocusedCell: () => {}
};

export default DataTable
