import React from 'react';
import {Button, ButtonGroup, Intent,  Card, ProgressBar, Elevation, Switch, HTMLSelect, TagInput, TextArea, Tree, Dialog, FormGroup, InputGroup, FileInput, Classes} from "@blueprintjs/core";
import {Flex, Box} from 'reflexbox'
import {inject, observer} from "mobx-react";
import DataTable from '../components/DataTables'
import moment from 'moment';
import { s3Upload } from "../utils/awsLib";
import {NotificationManager} from "react-notifications";

export default @inject("modalStore", "tubeStore")
@observer
class Tube extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            dialogData: null,
            selected: {},
        };
        this.props.tubeStore.fetchType();
        this.props.tubeStore.getByCat('Action');
    }

    onClickItem = (e) => {
        const {tubeStore} = this.props;
        tubeStore.getByCat(e.id);
        this.setState({selectedCat: e.id})
    };

    handleToggle = (e) => {
        console.log(e)
    };

    openDialog = (d, data) => {
        this.setState({dialog: d, dialogData: data})
    };

    isOpen = (d) => {
        return this.state.dialog === d
    };

    onEdit = () => {
        const {modalStore} = this.props;
        const {selected} = this.state;

        if (!selected.title) {
            NotificationManager.info("Please select one row of data")
        } else {
            modalStore.show('edit', selected)
        }
    };

    render() {
        const {tubeStore, modalStore} = this.props;
        const {selected} = this.state;

        console.log(selected)
        const columns = [
            {
                title: 'Title',
                key: 'title'
            }, {
                title: 'Category',
                key: 'category'
            }, {
                title: "Tags",
                key: 'tags',
                render: (e) => e.map(t => <span>{t}</span>)
            }, {
                title: "Thumbnail",
                key: 'thumbnail',
                render: (e) => <img src={e} height={24}/>
            }, {
                title: "Cover",
                key: 'cover',
                render: (e) => <img src={e} height={24}/>
            }, {
                title: 'Video',
                key: 'video'
            }, {
                title: 'Created At',
                key: 'created_at',
                render: e => moment(e).format('YYYY-MM-DD HH:mm')
            }, {
                title: 'Updated At',
                key: 'updated_at',
                render: e => moment(e).format('YYYY-MM-DD HH:mm')
            }];

        return <div>
            <Flex align='top' justify="center" m={2}>
                <Box w={1 / 4}>
                    <Card interactive={false} elevation={Elevation.TWO} style={{width: '100%'}}>
                        <Tree
                            contents={tubeStore.trees}
                            onNodeClick={this.onClickItem}
                            onNodeCollapse={e => this.handleToggle(e)}
                            onNodeExpand={e => this.handleToggle(e)}
                        />
                    </Card>
                </Box>
                <Box px={2} w={3 / 4}>
                    <Flex align='center' justify="left" m={2}>
                        <ButtonGroup>
                            <Button icon="plus" onClick={() => modalStore.show('add')}>Add</Button>
                            <Button icon="edit" onClick={this.onEdit}>Edit</Button>
                            <Button icon="trash"  onClick={() => modalStore.show('delete')}>Delete</Button>
                        </ButtonGroup>
                    </Flex>
                    <Card interactive={false} elevation={Elevation.TWO} style={{width: '100%'}}>
                        <DataTable dataSource={tubeStore.all} loading={tubeStore.isLoading} columns={columns} onFocusedCell={(cell, entity) => this.setState({selected: entity})}/>
                    </Card>
                </Box>
            </Flex>

            { modalStore.type === 'add' &&
                <TubeDialog dialogTitle={"Add Tube"} onClose={modalStore.close}/>
            }

            { modalStore.type === 'edit' &&
                <TubeDialog dialogTitle={"Edit Tube"} data={modalStore.data} onClose={modalStore.close}/>
            }

            <Dialog
                icon="caret-down"
                onClose={modalStore.close}
                title="Delete Product"
                isOpen={modalStore.type === 'delete'}
            >
                <div className={Classes.DIALOG_BODY}>
                    Are you sure to delete {selected.title}?
                </div>

                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={() => tubeStore.destroy(selected, () => this.setState({selected: {}}))}  loading={tubeStore.isLoading}>Delete</Button>
                    </div>
                </div>
            </Dialog>
        </div>
    }
}

@inject("tubeStore")
@observer
class TubeDialog extends React.Component {
    constructor(props) {
        super(props);
        const data = props.data || {};

        this.state = {
            videoProgress: null,
            thumbnailProgress: null,
            coverProgress: null,

            video: data.video,
            thumbnail: data.thumbnail,
            cover: data.cover,
            title: data.title,
            cast: data.cast,
            about: data.about,
            duration: data.duration,
            tags: data.tags? data.tags: [],
            category: data.category,
            feature: data.feature,
        }
    }

    onInputUpdate = (name) => (e) => {
        this.setState({[name]: e.target.value})
    };

    handleUpload = (name) => async event => {
        event.preventDefault();

        this.file = event.target.files[0];

        this.setState({[`${name}Progress`]: {loaded: 0, total: 1} });

        try {
            const attachment = this.file
                ? await s3Upload(
                    this.file,
                    (progress) => this.setState({[`${name}Progress`]: progress})
                )
                : null;

            console.log('attachment', attachment);

            this.setState({[name]: attachment})
        } catch (e) {
            alert(e);
            this.setState({ isLoading: false });
        }
    };

    handleUploadVideo = (name) => async event => {
        event.preventDefault();

        this.file = event.target.files[0];

        const setDuration = (d) => this.setState({ duration:  Math.round(d)});

        const reader = new FileReader();
        reader.onload = () => {
            const media = new Audio(reader.result);
            media.onloadedmetadata = function(){
                setDuration(media.duration) // this would give duration of the video/audio file
            };
        };
        reader.readAsDataURL(this.file);

        this.setState({[`${name}Progress`]: {loaded: 0, total: 1} });

        try {
            const attachment = this.file
                ? await s3Upload(
                    this.file,
                    (progress) => this.setState({[`${name}Progress`]: progress})
                )
                : null;

            console.log('attachment', attachment);

            this.setState({[name]: attachment})
        } catch (e) {
            alert(e);
            this.setState({ isLoading: false });
        }
    };

    handleSubmit = () => {
        const {cover, video, thumbnail, title, cast, about, tags, category, duration, feature} = this.state;
        const {tubeStore} = this.props;

        const data = {cover, video, thumbnail, title, cast, about, tags, category, duration, feature: feature? "yes": "no"};

        let newError = [];

        for (let key of Object.keys(data)) {
            if (!data[key]) {
                const e = key[0].toUpperCase() + key.slice(1) + " is required";
                this.setState({[`${key}Error`]: e});
                newError = newError.concat([e])
            }

            if (typeof data[key] === 'object' && data[key].length === 0) {
                const e = key[0].toUpperCase() + key.slice(1) + " is required";
                this.setState({[`${key}Error`]: e});
                newError = newError.concat([e])
            }
        }

        if (newError.length > 0) {
            NotificationManager.error("There is some error in the form, please check");
            return
        }

        if (this.props.data && this.props.data.title) {
            tubeStore.update(this.props.data.id, data)
        } else {
            tubeStore.create(data)
        }
    };

    render() {
        const {tubeStore, onClose, dialogTitle} = this.props;
        const {videoProgress, thumbnailProgress, coverProgress,
            cover, video, thumbnail, title, cast, about, tags, category, duration, feature,
            coverError, videoError, thumbnailError, titleError, castError, aboutError, tagsError, categoryError, durationError, featureError} = this.state;
        console.log(this.state);

        return <Dialog
            icon="database"
            onClose={onClose}
            title={dialogTitle}
            isOpen={true}
        >
            <div className={Classes.DIALOG_BODY}>
                <FormGroup
                    label="Video"
                    labelFor="video"
                    labelInfo="(required)"
                    intent={videoError && Intent.DANGER}
                    helperText={videoError}
                >
                    <FileInput id="video" text={video? video:'Select Video'} buttonText={"Upload"} onChange={this.handleUploadVideo("video")}/>
                    {videoProgress && videoProgress.loaded/videoProgress.total < 1 && <ProgressBar intent="primary" value={videoProgress.loaded/videoProgress.total}/>}
                </FormGroup>
                <FormGroup
                    label="Thumbnail"
                    labelFor="thumbnail"
                    labelInfo="(required)"
                    intent={thumbnailError && Intent.DANGER}
                    helperText={thumbnailError}
                >
                    <FileInput id="thumbnail" text={thumbnail? thumbnail:'Select image'} buttonText={"Upload"} onChange={this.handleUpload("thumbnail")}/>
                    {thumbnailProgress && thumbnailProgress.loaded/thumbnailProgress.total < 1 && <ProgressBar intent="primary" value={thumbnailProgress.loaded/thumbnailProgress.total}/>}
                </FormGroup>
                <FormGroup
                    label="Cover"
                    labelFor="cover"
                    labelInfo="(required)"
                    intent={coverError && Intent.DANGER}
                    helperText={coverError}
                >
                    <FileInput id="cover" text={cover? cover:'Select image'} buttonText={"Upload"} onChange={this.handleUpload("cover")}/>
                    {coverProgress && coverProgress.loaded/coverProgress.total < 1 && <ProgressBar intent="primary" value={coverProgress.loaded/coverProgress.total}/>}
                </FormGroup>
                <FormGroup
                    label="Title"
                    labelFor="title"
                    labelInfo="(required)"
                    intent={titleError && Intent.DANGER}
                    helperText={titleError}
                >
                    <InputGroup id="title" value={title} onChange={this.onInputUpdate('title')} placeholder="Please input video title" />
                </FormGroup>

                <FormGroup
                    helperText="Separate actors by comma"
                    label="Cast"
                    labelFor="cast"
                    labelInfo="(required)"
                    intent={castError && Intent.DANGER}
                    helperText={castError}
                >
                    <InputGroup id="cast" value={cast} onChange={this.onInputUpdate('cast')} placeholder="Please input cast" />
                </FormGroup>

                {/*<FormGroup*/}
                {/*    label="Duration"*/}
                {/*    labelFor="duration"*/}
                {/*    labelInfo="(required)"*/}
                {/*>*/}
                {/*    <InputGroup id="duration" value={duration} onChange={this.onInputUpdate('duration')} placeholder="Please input video duration" />*/}
                {/*</FormGroup>*/}

                <FormGroup
                    label="About"
                    labelFor="about"
                    labelInfo="(required)"
                    intent={aboutError && Intent.DANGER}
                    helperText={aboutError}
                >
                    <TextArea
                        id="about"
                        growVertically={true}
                        large={true}
                        onChange={this.onInputUpdate('about')}
                        value={about}
                    />
                </FormGroup>

                <FormGroup
                    helperText="Press Enter to create a tag"
                    label="Tags"
                    labelFor="tags"
                    labelInfo="(required)"
                    intent={tagsError && Intent.DANGER}
                    helperText={tagsError}
                >
                    <TagInput
                        onChange={(values) => this.setState({ tags: values })}
                        values={tags}
                    />
                </FormGroup>

                <FormGroup
                    label="Category"
                    labelFor="category"
                    labelInfo="(required)"
                    intent={categoryError && Intent.DANGER}
                    helperText={categoryError}
                >
                    <HTMLSelect value={category} onChange={this.onInputUpdate('category')}>
                        <option value={false}>-- Please select category --</option>
                        {tubeStore.types.map(e => <option value={e}>{e}</option>)}
                    </HTMLSelect>
                </FormGroup>
                <FormGroup
                    label="Set Feature"
                    labelFor="feature"
                    labelInfo="(required)"
                    intent={featureError && Intent.DANGER}
                    helperText={featureError}
                >
                    <Switch checked={feature} label="Public" onChange={(e) => this.setState({feature: e.target.value === 'on'})} />
                </FormGroup>
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={this.handleSubmit} loading={tubeStore.isLoading}>Submit</Button>
                </div>
            </div>
        </Dialog>

    }
}