import { Storage } from "aws-amplify";

export async function s3Upload(file, updateProgress) {
    // const filename = `${Date.now()}-${file.name}`;
    const filename = `${file.name}`;
    const baseUrl = `https://d2xym59va8u2yr.cloudfront.net/public/`;

    const stored = await Storage.put(filename, file, {
        contentType: file.type,
        progressCallback(progress) {
            updateProgress(progress);
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        },
    });

    const url = baseUrl + stored.key;

    console.log('url', url);
    return url;
}